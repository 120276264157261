@import "../../../node_modules/include-media/dist/include-media";
@import 'src/styles/functions';
@import 'src/styles/colors';

$posts: (1: 100%, 2: 150%, 3: 230%, 4: 305%, 5: 385%, 6: 460%);

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.squareImg {
  width: 100%;
  height: 300px;

  @include media (">=lg") {
    width: rem(legacy-rem(568));
    height: rem(legacy-rem(383));
    position: relative;
  }
}

.rectangleImage {
  width: 100%;
  height: 300px;

  @include media (">=lg") {
    width: rem(556);
    height: rem(340);
    position: relative;
  }
}

.verticalRectangle {
  width: 100%;
  height: 300px;

  @include media (">=lg") {
    width: rem(540);
    height: rem(608);
    position: relative;
  }
}

.smallerSquare {
  width: 100%;
  height: 300px;

  @include media (">=lg") {
    width: rem(531);
    height: rem(357);
    position: relative;
  }
}

.white_bg {
  margin-bottom: rem(legacy-rem(200));
  margin-top: rem(legacy-rem(50));
}

.motion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.masonryGrid {
  overflow: hidden;
  display: flex;
  width: 100%;

  @include media(">=1024px") {
    padding: rem(legacy-rem(50)) rem(250) 0;
    width: rem(1920);
    max-width: 100%;
  }
}

.masonryColumn  {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: rem(140);
}

.masonryColumn ~ .masonryColumn {
  align-items: flex-start;
  margin-top: 0;
}

@include media (">=lg") {
  .left {
    margin-right: rem(20);
  }
  .right {
    margin-left: rem(20);
  }
}

@include media (">=xl") {
  .left {
    margin-right: rem(70);
  }
  .right {
    margin-left: rem(70);
  }
}

.featured-posts {
  :global(.slick-slide) {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }


  :global(.slick-active) {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
  }

  :global(.slick-arrow) {
    display: none !important;
  }


  :global(.slick-dots) {
    bottom: rem(-50);
    display: flex !important;
    justify-content: center;
    align-items: center;

    & > li {
      opacity: 1;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
    }

    & > li > button {
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        display: block;
        background: url("../../../public/images/slider-dot.svg") no-repeat;
        width: 8px;
        height: 8px;
        position: relative;
        font-size: 0;
        opacity: .5;
      }
    }

    :global(.slick-active) {
      opacity: 1 !important;

      button {
        &::before {
          width: 16px;
          height: 16px;
          opacity: 1;
        }
      }
    }
  }

  > div {
    width: 100%;
  }

  @media (max-width: map_get($breakpoints, sm)) {
    margin-top: 0;
  }

  @include media("<lg") {
    max-width: 100%;
    padding-bottom: rem(90);
  }

  @include media("<md") {
    max-width: 100%;
    padding-bottom: rem(50);
  }

  @media (min-width: map_get($breakpoints, lg)) {
    max-width: 100%;
    padding: 0 rem(20);
  }

  @include media (">=lg") {
    padding-top: 0;
  }

  .featured-posts-mosiac {
    display: none;

    @include media(">=lg") {
      display: flex;
      padding: 0 rem(340) 0 rem(340);
      max-width: 1920px;
    }
  }

  &__post {
    padding: 0 rem(legacy-rem(90)) 0 rem(legacy-rem(90));

    @include media (">=lg") {
      padding: 0;
    }


    h4 {
      font-family: $bodyFont;
      font-size: rem(12);
      font-weight: 700;
      color: $secondary-lightgrey;
      margin: 0;

      @include media (">=md") {
        font-size: rem(13);
      }

      @include media (">=lg") {
        font-size: rem(legacy-rem(15));
      }
    }

    h3 {
      font-family: $bodyFont;
      font-size: rem(legacy-rem(24));
      line-height: rem(legacy-rem(36));
      cursor: pointer;

      @include media ("<lg") {
        font-size: rem(16);
        line-height: rem(25);
        min-height: rem(legacy-rem(88));
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    li {
      list-style: none;

      & > div {
        border-radius: 10px;
      }
    }

    &-image {
      cursor: pointer;

      &-square {
        position: relative;

        & > div > span > img {
          mask-image: url("../../../public/images/featuredPostSquareMask.svg");
          mask-position: top center;
          mask-size: contain;
          mask-repeat: no-repeat;
          -webkit-mask-image: url("../../../public/images/featuredPostSquareMask.svg");
          -webkit-mask-position: top center;
          -webkit-mask-size: contain;
          -webkit-mask-repeat: no-repeat;

          @media (max-width: map_get($breakpoints, sm)) {
            mask-image: url("../../../public/images/featuredPostsMask.svg");
            -webkit-mask-image: url("../../../public/images/featuredPostsMask.svg");
          }
        }
      }

      &-rectangle {
        position: relative;

        & > div > span > img {
          mask-image: url("../../../public/images/featuredPostRectangleMask.svg");
          mask-position: top center;
          mask-size: contain;
          mask-repeat: no-repeat;
          -webkit-mask-image: url("../../../public/images/featuredPostRectangleMask.svg");
          -webkit-mask-position: top center;
          -webkit-mask-size: contain;
          -webkit-mask-repeat: no-repeat;

          @media (max-width: map_get($breakpoints, sm)) {
            mask-image: url("../../../public/images/featuredPostsMask.svg");
            -webkit-mask-image: url("../../../public/images/featuredPostsMask.svg");
          }
        }
      }

      &-verticalRectangle {
        position: relative;

        & > div > span > img {
          mask-image: url("../../../public/images/featuredPostVerticalMask.svg");
          mask-position: top center;
          mask-size: contain;
          mask-repeat: no-repeat;
          -webkit-mask-image: url("../../../public/images/featuredPostVerticalMask.svg");
          -webkit-mask-position: top center;
          -webkit-mask-size: contain;
          -webkit-mask-repeat: no-repeat;

          @include media("<lg") {
            mask-image: url("../../../public/images/featuredPostsMask.svg");
            -webkit-mask-image: url("../../../public/images/featuredPostsMask.svg");
          }
        }
      }
    }

    &--placeholder > div {
      width: rem(legacy-rem(200));
    }
  }

  .featured-posts__download {
    width: rem(legacy-rem(24));
    margin-top: 0;
    margin-left: rem(legacy-rem(10));
    margin-bottom: 0;
  }

  .featured-posts__h4-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: rem(legacy-rem(20));
    margin-bottom: rem(legacy-rem(29));

    @include media("<lg") {
      margin-bottom: rem(19);

      h4 {
        font-size: rem(12);
        line-height: rem(20);
      }
    }
  }

  .h3-container {
    @include media("<lg") {
      h3 {
        font-size: rem(16);
        line-height: rem(25);
        margin-bottom: 0;
      }
    }
  }

  .featured-posts__post {
    position: relative;

    &--hidden {
      display: none;
    }

    a {
      text-decoration: none;
      line-height: unset;
      display: flex;
    }

    .cursor {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 80px;
      pointer-events: none;
      border-radius: 50%;
      background: $brand-pink;
      padding: rem(legacy-rem(10));
      color: #fff;
      text-align: center;
      font-weight: 400;
      font-size: rem(legacy-rem(12));

      @include media("<lg") {
        display: none;
      }

      &::before {
        content: '<';
        margin-left: -5px;
        padding-right: 5px;

        @include media(">=lg") {
          display: none;
        }
      }

      &::after {
        content: '>';
        margin-right: -5px;
        padding-left: 5px;

        @include media(">=lg") {
          display: none;
        }
      }

      &Wrapper {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity .15s ease;
      }
    }
  }

  &__post {
    flex-direction: column;

    @include media (">=lg") {
      flex-basis: 50%;
    }
  }

  .featured_posts__slider-row {
    margin-left: 0;
    margin-right: 0;
  }

  .featured-posts__slider {
    .featured-posts__post {
      padding: 0 rem(legacy-rem(15)) 0 rem(legacy-rem(15));
    }

    &-wrapper {
      height: rem(legacy-rem(450));
      @include media("<lg") {
        height: rem(legacy-rem(600));
      }
      @include media("<sm") {
        height: rem(legacy-rem(550));
      }
      @include media("<xs") {
        height: rem(legacy-rem(410));
      }
      @include media(">=xxl") {
        width: rem(legacy-rem(1920));
      }

      // Loops through array, assigning container width according to number of posts
      // Creates zoomed out appearance where adjacent posts can be seen in mobile slider
      @each $post, $width in $posts {
        .posts-#{$post} {
          ul {
            // Override inline styles from carousel package
            width: $width !important;
            @if $post == 1 {
              li {
                & > div {
                  margin-left: rem(legacy-rem(3));
                }
              }
            }
          }
        }
      }

      ul {
        .carousel__slide--hidden {
          opacity: 0.5;
        }

        li {
          & > div {
            margin-left: 15%;
          }
        }
      }
    }

    &-slide {
      .featured-posts__post {
        display: flex;
        flex-direction: column;

        @media (max-width: map_get($breakpoints, sm)) {
          align-items: center;
          padding-top: rem(legacy-rem(30));
        }

        &--placeholder {
          display: none;

          @include media(">=lg") {
            display: flex;
          }
        }

        h3, h4 {
          align-self: flex-start;
        }
      }


    }

    &-dots {
      display: flex;
      justify-content: center;

      button {
        width: rem(legacy-rem(12));
        margin-left: 0.2rem;
        background-color: transparent;
        fill: $brand-pink;
        border: none;
        opacity: 0.7;

        &:disabled {
          opacity: 1;
          height: rem(legacy-rem(20));
          width: rem(legacy-rem(20));
        }
      }
    }
  }

  .featured-posts__load-more {
    display: none;

    @include media(">=lg") {
      display: flex;
      margin-left: rem(300);
      margin-top: rem(-180);
    }

    button {
      font-size: rem(legacy-rem(22));
      font-weight: bold;
      z-index: 2;
    }
  }
}

.posts_slider {
  @include media(">=lg") {
    padding-bottom: rem(50);
  }
}

.quote_title {
  display: block;
  font-size: rem(legacy-rem(40));
  line-height: rem(legacy-rem(55));
  color: $black;
  text-decoration: underline;
  text-decoration-color: #fdf2b3;
  text-decoration-thickness: rem(legacy-rem(10));

  @media (max-width: map_get($breakpoints, lg)) {
    font-size: rem(legacy-rem(17));
    line-height: rem(legacy-rem(30));
    margin-bottom: rem(legacy-rem(10));
  }
}

.post-description-wrapper {
  max-width: rem(legacy-rem(600));
  flex-direction: column;

  @include media (">=lg") {
    margin-bottom: rem(94);
  }
}
