@import "~include-media/dist/include-media";
@import '../../styles/common';

.alternateBgWrapper {
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &.curve-outline {
    &.alternateBg--light {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: rem(-1);
    }

    &.alternateBg--dark {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: rem(-1);
    }

    &.alternateBg--image {
      padding-top: 0;
      padding-bottom: 0;
    }

    &::after {
      content: '';
      display: block;
      background: url("../../../public/images/white-curve-bottom-mobile.svg") no-repeat center 1px;
      background-size: cover;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rem(70);
      margin-top: rem(-70);

      @include media(">=sm") {
        height: rem(100);
        margin-top: rem(-100);
      }

      @include media(">=md") {
        height: rem(140);
        margin-top: rem(-140);
      }

      @include media(">=lg") {
        height: rem(200);
        margin-top: rem(-180);
      }

      @include media(">=xl") {
        margin-top: rem(-170);
      }

      @include media(">=sm") {
        background: url("../../../public/images/white-curve-bottom.svg") no-repeat center 1px;
        background-size: cover;
      }

      @include media(">=xl") {
        background: url("../../../public/images/white-curve-bottom-4k.svg") no-repeat center 1px;
        background-size: cover;
      }
    }

    .childContainer {
      padding-top: rem(30);
      padding-bottom: rem(30);

      @include media(">=md") {
        padding-top: rem(60);
        padding-bottom: rem(60);
      }

      @include media(">=lg") {
        padding-top: rem(legacy-rem(60));
        padding-bottom: rem(legacy-rem(60));
      }
    }
  }
}

.alternateBg {
  &--light {
    background-color: $secondary-grey;
    padding-top: rem(legacy-rem(60));
    padding-bottom: rem(legacy-rem(60));

    @include media(">=md") {
      padding-top: rem(legacy-rem(100));
      padding-bottom: rem(legacy-rem(100));
    }
  }

  &--dark {
    background-color: $primary;

    * {
      color: $white;
    }
  }

  &--image {
    position: relative;
    background-position: center;
    background-size: cover;
    padding-top: rem(legacy-rem(60));
    padding-bottom: rem(legacy-rem(60));

    @include media(">=md") {
      padding-top: rem(legacy-rem(100));
      padding-bottom: rem(legacy-rem(100));
    }

    * {
      color: $white;
    }

    &::after {
      display: block;
      content: '';
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.childContainer {
  position: relative;
  z-index: 1;
}

.curvedTop {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: rem(90);
  margin-top: rem(-90);
  position: relative;
  z-index: -1;

  @include media(">=sm") {
    height: rem(80);
    margin-top: rem(-80);
  }

  @include media(">=lg") {
    height: rem(120);
    margin-top: rem(-120);
  }

  @include media(">=xl") {
    height: rem(160);
    margin-top: rem(-160);
  }

  @include media(">=xxl") {
    height: rem(200);
    margin-top: rem(-200);
  }

  @include media(">=xxxl") {
    height: rem(220);
    margin-top: rem(-220);
  }

  @include media(">=xxxxl") {
    height: rem(250);
    margin-top: rem(-250);
  }

  &--dark {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 376.7 38.5' style='enable-background:new 0 0 376.7 38.5;' xml:space='preserve'%3E%3Cg%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230f1822;%7D %3C/style%3E%3Cg%3E%3Cpath class='st0' d='M2.3,30.5L0,30.3v8.2h147.1c-21.6,0-44.4-0.6-68-1.9C54.4,35.2,28.6,33.2,2.3,30.5z'/%3E%3Cpath class='st0' d='M376.6,0.1l-1.5,0.8c-1.3,0.7-2.8,1.4-4.4,2.2c-4.8,2.3-10.1,4.5-15.9,6.7c-7.2,2.7-15.1,5.3-23.5,7.7 c-21.3,6.1-45.7,10.9-72.7,14.4c-33.7,4.4-71.2,6.6-111.6,6.6h229.6L376.6,0.1L376.6,0.1z'/%3E%3C/g%3E%3Cpath class='st0' d='M376.7,0L376.7,0L376.7,0z'/%3E%3C/g%3E%3C/svg%3E");

    @include media(">=sm") {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='154.002' viewBox='0 0 1920 154.002'%3E%3Cpath id='Subtraction_12' d='M2052,1272H132V1118l0,81.377c45.212,8.159,92.647,15.893,140.987,22.984,61.624,9.042,124.457,17,186.754,23.649,161.049,17.206,319.374,25.931,470.578,25.931,35.374,0,70.771-.486,105.206-1.445,33.13-.918,67.009-2.323,100.7-4.178,32.467-1.786,65.644-4.028,98.61-6.664,61.576-4.922,125.01-11.4,188.539-19.26,106.971-13.226,216.418-30.586,325.3-51.6,42.649-8.231,85.381-17.068,127.01-26.267,33.124-7.32,65.677-14.9,96.753-22.527,41.828-10.264,69.574-17.843,79.562-20.625V1272Z' transform='translate(-132 -1118)' fill='%230f1822'/%3E%3C/svg%3E%0A");
    }

    @include media(">=xxl") {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 4000 198.7' style='enable-background:new 0 0 4000 198.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230f1822;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M3877.8,17.4c-56.4,7.6-117.1,15.3-180.3,22.9c-79.6,9.5-162.8,18.8-247.4,27.6 c-216.6,22.5-446.8,42.4-684.2,59c-141.2,9.9-286.2,18.7-431,26.2c-158.9,8.3-322.5,15.2-486.3,20.6 c-141.8,4.7-282.9,8.7-419.5,12.1c-126.1,3.1-252.3,5.7-375.2,7.7c-212.5,3.4-419,5.2-613.9,5.2h3560V0 C3975.6,3.7,3934.6,9.8,3877.8,17.4z'/%3E%3Cpath class='st0' d='M0,198.7h440c-156.8,0-304.8-1.1-440-3.3V198.7z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--light {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 376.7 38.5' style='enable-background:new 0 0 376.7 38.5;' xml:space='preserve'%3E%3Cg%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F7F7F7;%7D %3C/style%3E%3Cg%3E%3Cpath class='st0' d='M2.3,30.5L0,30.3v8.2h147.1c-21.6,0-44.4-0.6-68-1.9C54.4,35.2,28.6,33.2,2.3,30.5z'/%3E%3Cpath class='st0' d='M376.6,0.1l-1.5,0.8c-1.3,0.7-2.8,1.4-4.4,2.2c-4.8,2.3-10.1,4.5-15.9,6.7c-7.2,2.7-15.1,5.3-23.5,7.7 c-21.3,6.1-45.7,10.9-72.7,14.4c-33.7,4.4-71.2,6.6-111.6,6.6h229.6L376.6,0.1L376.6,0.1z'/%3E%3C/g%3E%3Cpath class='st0' d='M376.7,0L376.7,0L376.7,0z'/%3E%3C/g%3E%3C/svg%3E");

    @include media(">=sm") {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='154.002' viewBox='0 0 1920 154.002'%3E%3Cpath id='Subtraction_12' d='M2052,1272H132V1118l0,81.377c45.212,8.159,92.647,15.893,140.987,22.984,61.624,9.042,124.457,17,186.754,23.649,161.049,17.206,319.374,25.931,470.578,25.931,35.374,0,70.771-.486,105.206-1.445,33.13-.918,67.009-2.323,100.7-4.178,32.467-1.786,65.644-4.028,98.61-6.664,61.576-4.922,125.01-11.4,188.539-19.26,106.971-13.226,216.418-30.586,325.3-51.6,42.649-8.231,85.381-17.068,127.01-26.267,33.124-7.32,65.677-14.9,96.753-22.527,41.828-10.264,69.574-17.843,79.562-20.625V1272Z' transform='translate(-132 -1118)' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
    }

    @include media(">=xxl") {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 4000 198.7' style='enable-background:new 0 0 4000 198.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F7F7F7;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M3877.8,17.4c-56.4,7.6-117.1,15.3-180.3,22.9c-79.6,9.5-162.8,18.8-247.4,27.6 c-216.6,22.5-446.8,42.4-684.2,59c-141.2,9.9-286.2,18.7-431,26.2c-158.9,8.3-322.5,15.2-486.3,20.6 c-141.8,4.7-282.9,8.7-419.5,12.1c-126.1,3.1-252.3,5.7-375.2,7.7c-212.5,3.4-419,5.2-613.9,5.2h3560V0 C3975.6,3.7,3934.6,9.8,3877.8,17.4z'/%3E%3Cpath class='st0' d='M0,198.7h440c-156.8,0-304.8-1.1-440-3.3V198.7z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
