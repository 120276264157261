@use "sass:math";

$phi: 1.618;
$baseline: 10;
$container-padding: ($baseline * 2px) ((100% - math.div(100, $phi)) * 0.25);

// Font families
$toolTipFont: 'NanumPen', sans-serif;
$headerFont: 'HKNova', sans-serif;
$formInputFont: 'HKNova', sans-serif;
$bodyFont: 'Quicksand', sans-serif;

// Font weights
$regular: 400;
$medium: 500;
$bold: 700;

// Font sizes
$default-body-font-size: 16px;
$base-font-sizes: (
  xs: 16px,
  md: 16px,
  lg: 16px,
);

// Links
$link-transition-time: 500ms;

// Include media sizing
$breakpoints: (
  xxs: 320px,
  xs: 460px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1720px,
  xxxxl: 2560px,
  xxxxxl: 3840px
);

$grid-breakpoints: $breakpoints;

$container-gutter-width: 260px;
$mobile-container-gutter-width: 50;
$grid-gutter-width: 108px;
$grid-gutter-width-small: 50px;
$grid-gutter-width-medium: 180px;
$grid-gutter-width-large: 150px;

$container-max-width: (
  sm: calc(100% - #{$grid-gutter-width-small}),
  md: calc(100% - #{$grid-gutter-width-medium}),
  lg: calc(100% - #{$grid-gutter-width-large}),
  xl: 1610px - $container-gutter-width,
);
