@import "../../../../node_modules/include-media/dist/include-media";
@import 'src/styles/functions';
@import 'src/styles/colors';

.freshly-published {
  width: rem(legacy-rem(90));
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(30);

  @include media(">=lg") {
    margin-bottom: rem(-20);
    width: rem(legacy-rem(150));
    margin-left: rem(50);
    margin-right: unset;
  }

  span {
    font-family: $toolTipFont;
    font-size: rem(legacy-rem(24));
    line-height: rem(legacy-rem(26));
    position: absolute;
    transform-origin: 50% 50%;
    display: flex;
    flex-direction: column;
    transform: rotate(-10deg);
    color: rgb($primary, 0.6);
    max-width: rem(80);

    @include media("<lg") {
      font-size: rem(16);
      line-height: rem(16);
      font-weight: normal;
      max-width: rem(50);
    }
  }

  @media (max-width: map_get($breakpoints, sm)) {
    margin-bottom: rem(-10);
  }
}

