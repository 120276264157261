// Primary colours
$primary: #0f1822;
$primary-lightblue: #29BBEA;
$brand-pink: #FF6274;
$brand-blue: #2ECCFF;

// Secondary colours
$secondary-red: $brand-pink;
$secondary-yellow: #FFCA39;
$secondary-orange: #FC9B00;
$secondary-purple: #A776D1;
$secondary-green: #4DB501;
$secondary-black: #16212E;
$secondary-blue: #EAF8FD;
$secondary-grey: #F7F7F7;
$secondary-darkgrey: #434343;
$secondary-darkgrey2: #4A4A4A;
$secondary-darkgrey3: #394D5F;
$secondary-darkgrey4: #8A98AC;
$secondary-lightgrey: #707070;
$secondary-lightgrey2: #A2A2A2;
$secondary-lightgrey3: #F4F4F4;
$secondary-lightgrey4: #E6E6E6;
$secondary-pink: #C64A58;



// Additional colours
$white: #FFF;
$black: #000;
$dark-blue: #4294B9;
$light-green: #9ed974;
$active-tab: #FFDC7C;
$border-bottom-grey: #C9C9C9;
$download-size-grey: #717274;
